import './App.css';
import Member from './members'

function App() {
  return (
    <>
    <Member></Member>
    </>
  );
}

export default App;
