import React, {useState, useEffect} from 'react';

const Members = () => {
    const [members, setMembers] = useState([]);
  
    useEffect(() => {
        const apiUrl = 'https://api.sleeper.app/v1/league/515466257395929088/users';
        fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => setMembers(data))
    }, []);

    return <>
    <div className="container">
        {members.map((member) => {
            var avatarImage = 'https://sleepercdn.com/avatars/thumbs/' + member.avatar
            
            return (
                <div className="info" key={member.user_id}>
                    <div className="image">
                        <img src={avatarImage}></img>
                    </div>
                    
                    <div className="text">
                        <h4>{member.display_name}</h4>
                        <h5>{member.metadata.team_name}</h5>
                    </div>
                </div>
            );
        })}
    </div>
    </>
  
}
export default Members